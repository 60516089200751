.Breadcrumb {
  padding: 1em 0;
  font-size: 0.9em;

  .Nav {
    display: inline;
    margin-left: 0.5em;
  }

  li + li:before {
    content: "/";
    padding: 0 0.5rem;
  }

  a {
    text-decoration: underline;
  }
}
