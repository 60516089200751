.EasiFastCheckInModule {
  padding: 4em 0;
}

.EasiFastCheckInModule__Iframe {
  width: 100%;
  border: 0;
  height: 23em;
}

.EasiFastCheckInModule__Iframe--no-click {
  pointer-events: none;
}

.EasiFastCheckInModule__LogoContainer {
  height: 8em;
}

.EasiFastCheckInModule__Logo {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
