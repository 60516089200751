.BookingModule__Container {
  overflow: hidden;
}

.BookingModule {
  padding: 1.5em 0;
}

@include mq(large) {
  .BookingModule {
    padding: 3em 0;
  }
}
