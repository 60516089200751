// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Open Sans", "Helvetica Neue Light", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif !default;

/// Copy light text color
/// @type Color
$light-text-color: rgb(94, 109, 130) !default;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Highlighted color
/// @type Color
$highlighted-color: rgb(71, 170, 221) !default;

/// Action Color
/// @type Color
$action-color: rgb(252, 93, 66) !default;

/// Light grey
/// @type Color
$light-grey: rgb(233, 238, 243) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

$white: rgb(255, 255, 255) !default;

/// Container's maximum width
/// @type Length
$max-width: 1140px !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/assets/" !default;
