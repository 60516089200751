.WebcamModule {
  margin-bottom: 2em;
}

.WebcamModule__Inner {
  display: flex;
  flex-direction: column;
}

.WebcamModule__Inner--image-small {
  flex-direction: column;
  justify-content: space-between;
}

.WebcamModule__Image {
  margin: 0;
  padding: 0;
  width: 100%;
}

.WebcamModule__PlaceholderImg {
  width: 100%;
  height: 100%;
}

.WebcamModule__ImageCaption {
  padding: 0.5em;
}

.WebcamModule__Text {
  margin-bottom: 2em;
}

.WebcamModule__Image--image-last {
  order: 1;
}

@include mq(medium) {
  .WebcamModule__Inner--image-small {
    flex-direction: row;

    .WebcamModule__Text {
      width: 56%;
    }

    .WebcamModule__Subtitle {
      margin-top: 0;
    }

    .WebcamModule__Image {
      width: 40%;
    }
  }
}

.WebcamModule__Inner--image-big {
  .WebcamModule__Text {
    margin-top: 2em;
  }
}
