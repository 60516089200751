// The icons in Google Maps aren’t displayed
// with box-sizing: border-box;
.MapModule {
  box-sizing: content-box;
}

.MapModule__Container {
  height: 400px;
}

.MapModule__TextOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 1.6em;
  padding: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  background: url("/public/dummy_map.jpg") no-repeat center center;
  background-size: cover;
}
