.Button {
  padding: 0.4em 1em;
  border: 0;
  cursor: pointer;
  color: #fff;
  border: 1px solid;
  text-decoration: none;
  display: inline-block;
  opacity: 1;
  transition: opacity 0.5s;
  font-size: 1.1em;
  line-height: inherit;
  text-align: center;
  min-width: 7.5em;

  &:active,
  &:focus,
  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}

.Button--small {
  padding: 0.5em 1em;
}

.Button--hollow {
  background: transparent;
}

.ButtonGroup {
  margin-top: 1.5em;
  display: flex;
  flex-wrap: wrap;
  margin: 1.5em 0 0 -0.25em;
  width: calc(100% + 0.5em);

  .Button {
    margin: 0.25em;

    &:only-child {
      flex-grow: 0;
    }
  }
}

.ButtonGroup--full-width .Button {
  flex-grow: 1;
}

.ButtonGroup--align-left {
  justify-content: flex-start;
}

.ButtonGroup--align-center {
  justify-content: center;
}

.ButtonGroup--align-right {
  justify-content: flex-end;
}

.ButtonGroup--flex {
  width: auto;
}
