.LanguageMenu {
  padding-top: 1em;
  padding-bottom: 1em;
  order: 0;
  align-items: baseline;
}

.LanguageMenu__Inner {
  display: inline-flex;
  align-items: baseline;
  position: relative;
}

.LanguageMenu--all-in-header {
  padding: 1em;
}

.LanguageMenu--horizontal {
  .LanguageMenu__List {
    flex-direction: row;
  }

  .LanguageMenu__ListItem:not(:first-child)::before {
    content: "|";
    padding: 0 0.3em;
  }
}

.LanguageMenu--vertical {
  .LanguageMenu__List {
    flex-direction: column;
  }
}

.LanguageMenu__List {
  // Never show the language list on narrow viewports.
  // Show the language <select> instead
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.LanguageMenu__Item:hover {
  text-decoration: underline;
}

.LanguageMenu__Item {
  padding: 0.5em;
  color: inherit;
}

@include mq(medium) {
  .LanguageMenu--all-in-header {
    position: static;
    padding: 1em;
  }

  .LanguageMenu__Item {
    padding: 0;
  }
}

@include mq(large) {
  .LanguageMenu__List {
    display: flex;
  }

  .LanguageMenu--show-list-on-wide .LanguageMenu__Inner {
    display: none;
  }
}

.LanguageMenu--bottom-right {
  bottom: 0;
  right: 0;
  position: absolute;
}

.LanguageMenu__Select {
  border: 0;
  color: inherit;
  background-color: inherit;
  font-size: inherit;
  text-shadow: inherit;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.LanguageMenu__SelectOption {
  color: black;
  background-color: white;
}

.LanguageMenu__Icon {
  fill: currentColor;
}

.LanguageMenu__Icon--front {
  margin-right: 0.25em;
}

.LanguageMenu__Icon--dropdown {
  top: 0.35em;
}

.LanguageMenu__SelectedItem {
  display: inline-flex;
}
