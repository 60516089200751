.PeerTvModule {
  padding: 4em 0;
}

.PeerTvModule_Content {
  text-align: center;
}

.PeerTvModule__Icon {
  font-size: 5em;
}

.PeerTvModule_Content--small {
  max-width: calc(1000px + 3%);
}

.PeerTvModule_Content--full-width {
  max-width: 100%;
}
