.CookieConsent {
  background-color: #eee;
  color: #5e6c82;
  fill: currentColor;
  font-size: 0.75em;
  position: relative;
  z-index: 10;
}

.CookieConsent__Wrapper {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CookieConsent__Button {
  font-size: 1.3em;
  padding: 0.2em;
  margin-left: 1em;
}

.CookieConsent a {
  color: inherit;
  text-decoration: underline;
}
