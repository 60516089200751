.Table {
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;

  th {
    font-weight: normal;
    text-align: left;

    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  tbody tr {
    background: #313541;
  }

  th,
  td {
    padding: 0.75rem;
  }

  td {
    .Btn {
      padding: 0.75em;
      font-size: 0.8em;
    }
  }

  .Table__Cell--text-right {
    text-align: right;
  }
}

.TableWrapper {
  background: #313541;

  .Table {
    padding: 0.5em 1em;

    th {
      padding-top: 0;
    }

    td {
      font-size: 0.85em;
      background: #1f2633;
    }
  }
}
