.HeaderModule__Inner--wide {
  display: none;
}

.HeaderModule--wide-menu-fits {
  .HeaderModule__Inner--wide {
    display: block;
  }

  .HeaderModule__Inner--narrow {
    display: none;
  }
}

.HeaderModule__Inner {
  position: relative;
  z-index: 20;
}

@include mq(large) {
  .Site__Inner--with-sidebar {
    margin-left: 5em;
  }
}

@media (max-aspect-ratio: 21 / 9) {
  .Site--is-home .HeaderModule__Inner--full-height {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .HeaderModule__Inner--full-height {
    height: 85vh;
  }
}

.HeaderModule__TopBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.HeaderModule__BottomBlock {
  position: relative;
}

.HeaderModule__Language_and_MainMenu {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
  order: 0;
  min-height: 7.6em;

  &:empty {
    display: none;
  }
}

.HeaderModule__Inner--top-below-header-nav,
.HeaderModule__Inner--top-in-header-nav {
  .HeaderModule__Language_and_MainMenu {
    display: none;
  }
}

.HeaderModule__MainMenuHamburger {
  color: #fff;
  list-style: none;
  margin: 0;
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  width: 100%;

  // Pushes the flex item to the bottom if it’s the only element
  margin-top: auto;
}

.MainMenu__ExpandButton {
  padding: 0;
  border: 0;
  color: inherit;
  background: transparent;
  outline: 0;
  padding: 0 0.5em;
}

.MainMenu__ExpandIcon {
  fill: currentColor;
}

.MainMenu__ItemWrap {
  display: flex;
}

.HeaderModule__MainMenuHamburger__Box {
  .MainMenu__ItemLevel0 {
    padding: 0.8em 1.1em;
  }

  .MainMenu__ItemLevel1 {
    padding: 0.5em 2.7em;
  }

  .MainMenu__ItemLevel2up {
    padding: 0.4em 3.2em;
  }

  .MainMenu__ItemIcon {
    border-radius: 50%;
    font-size: 0.65em;
    margin-right: 0.7em;
  }
}

.HeaderModule__MainMenuHamburger__MenuButton {
  position: relative;
  z-index: 10;
  white-space: nowrap;
  min-width: 0;
}

.HeaderModule__MainMenuHamburger__Box {
  align-items: stretch;
  flex-direction: column;
  display: none;
  left: 0;
  position: absolute;
  width: 30em;
  transition: opacity 0.2s;
  max-width: 21em;

  // viewport height - hamburger button heighgt
  max-height: calc(100vh - 3.4em);
  overflow-y: auto;
}

.HeaderModule__MainMenuHamburger__Box--open {
  display: block;
}

.HeaderModule__MainMenuHamburger__MenuIcon {
  fill: #fff;
  font-size: 1.3em;
}

.MainMenu__Item:not(.MainMenu__ItemLevel0):hover,
.MainMenu__Item--active:not(.MainMenu__ItemLevel0) {
  text-decoration: underline;
}

.MainMenu__ItemLevel0 {
  font-size: 1.1em;
}

.MainMenu__ListItemLevel0 {
  position: relative;
}

.MainMenu__Item {
  display: block;
  color: inherit;

  &:only-child {
    width: 100%;
  }
}

.MainMenu__ItemLevel1 {
  padding: 0.5em 1.6em;
}

.MainMenu__ItemLevel2up {
  padding: 0.15em 1.9em;
}

.MainMenu__ItemLevel2up .MainMenu__Item__Text {
  font-size: 0.8em;
}

.MainMenu__ListItem {
  line-height: 1.2;
}

.MainMenu__List {
  padding: 0;
  margin: 0;
  list-style: none;
}

.MainMenu__ListLevel0 {
  font-size: 1.2em;
}

.MainMenu__Wrapper--horizontal {
  .MainMenu__ListItemLevel0:not(:first-child) .MainMenu__ItemLevel0::before {
    content: "";
    display: block;
    width: 1px;
    height: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: currentColor;
  }

  .MainMenu__ListItemLevel0:hover .MainMenu__ListLevel1 {
    display: block;
  }

  .MainMenu__ListItemLevel0:hover .MainMenu__ItemLevel0,
  .MainMenu__ItemLevel0.MainMenu__Item--active {
    opacity: 0.8;
  }

  .MainMenu__ListLevel1 {
    display: none;
    left: 0;
    min-width: 100%;
    padding: 0.5em 0;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    z-index: 20;
  }

  .MainMenu__List--last-right-align
    .MainMenu__ListItemLevel0:last-child
    .MainMenu__ListLevel1 {
    left: auto;
    right: 0;
  }

  .MainMenu__ListLevel0 {
    display: flex;
  }

  .MainMenu__ItemLevel0 {
    padding: 0.8em 1.5em;
    text-align: center;
    line-height: 1.2;
    display: flex;
    align-items: center;
  }

  .MainMenu__ListItemLevel0 {
    white-space: nowrap;
    position: relative;
    z-index: 20;
    display: flex;
  }
}

.MainMenu__Wrapper {
  width: 100%;
  z-index: 20;
}

.MainMenu__Wrapper--absolute {
  position: absolute;
}

.HeaderModule__Inner--top-below-header-nav {
  .MainMenu__ListLevel0 {
    z-index: 10;
    justify-content: center;
  }
}

.HeaderModule__Links {
  display: flex;
  flex-flow: column-reverse nowrap;
}

@include mq(medium) {
  .HeaderModule__Links {
    text-align: right;
  }
}

.HeaderModule__Contact {
  align-self: flex-end;
  text-align: right;
  padding: 0.25em;
  margin-top: auto;
  right: 0;
  bottom: 0;
  font-size: 1.4em;
}

.ContactNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
}

.ContactNav__Icon {
  margin-right: 0.25em;
  fill: currentColor;
}

.ContactNav--vertical {
  flex-direction: column;
}

.ContactNav__ListItem {
  display: block;
  position: relative;

  &:not(:last-child)::after {
    display: none;
  }
}

.ContactNav__ListItem::before {
  display: none;
}

@include mq(small) {
  .ContactNav__ListItem {
    &:not(:last-child)::after {
      display: inline;
    }
  }
}

@include mq(medium) {
  .HeaderModule__Contact {
    font-size: 1em;
    padding: 1em;
  }

  .ContactNav__ListItem {
    display: block;

    &::before {
      display: inline;
    }

    &:not(:last-child)::after {
      display: none;
    }
  }
}

@include mq(large) {
  .ContactNav__ListItem {
    display: inline-block;

    &:not(:last-child)::after {
      display: inline;
    }
  }
}

.HeaderModule__Logo {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.ActionModuleNavigation {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ActionModuleNavigation__ListItem {
  position: relative;
}

.ActionModuleNavigation--in-header-nav {
  display: none;
}

.ActionModuleNavigation--split-menu {
  display: block;
}

@include mq(medium) {
  .ActionModuleNavigation--hamburger {
    display: none;
  }
}

.ActionModuleNavigation__LinkItem {
  color: inherit;
}

.HeaderModule__MainMenuHamburger--fixed {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 40;

  .ActionModuleNavigation--hamburger {
    display: flex;
  }
}

.HeaderModule__Inner--top-in-header-nav {
  .ActionModuleNavigation--in-header-nav {
    display: flex;
  }

  .HeaderModule_BlockA {
    align-self: stretch;
  }
}

.HeaderModule__Inner--top-in-header-nav {
  .HeaderModule__Links {
    flex-direction: column;
  }

  .HeaderModule__Contact {
    margin-top: 0;

    &:only-child {
      margin-left: auto;
    }
  }
}

.ActionModuleNavigation__Icon {
  fill: currentColor;
  margin-right: 0.25em;
}

.ActionModuleNavigation--block {
  display: none;
}

.ActionModuleNavigation--on-narrow {
  display: none;
}

.HeaderModule__Inner--top-hamburger-nav .ActionModuleNavigation--on-narrow {
  display: flex;
}

@include mq(medium) {
  .HeaderModule__Inner--top-hamburger-nav .ActionModuleNavigation--on-narrow {
    display: none;
  }

  .HeaderModule__Inner--top-hamburger-nav .ActionModuleNavigation--block {
    display: flex;
  }
}

.HeaderModule__Inner--top-below-header-nav .ActionModuleNavigation--block {
  display: flex;
}

.ActionModuleNavigation__Item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 0;
}

@include mq(small) {
  .ActionModuleNavigation__Item {
    min-width: 7.5em;
  }
}

@include mq(medium) {
  .HeaderModule__Block1 {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
}

.ContactNav__Item {
  white-space: nowrap;
  color: inherit;
}

.ContactNav__ItemText {
  display: none;
}

@include mq(medium) {
  .ContactNav__ItemText {
    display: inline;
  }
}

.MainMenu__Wrapper--below-header-nav {
  justify-content: center;
}

.MainMenu__Wrapper {
  display: flex;
  flex: 1;
}

.MainMenu__Container {
  display: flex;
}

.HeaderModule__BlockF {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  flex: 1;
  position: relative;
}

.HeaderModule__Logo + .HeaderModule__BlockF {
  margin-left: 0;
}

.HeaderModule__Inner--top-in-header-nav .HeaderModule__BlockF {
  flex: 1;
}

.HeaderModule_BlockA {
  display: none;
  align-items: flex-end;
  margin-top: auto;
}

.CompactHeaderBar .MainMenu__Wrapper--horizontal {
  display: none;
}

.HeaderModule__Inner--top-in-header-nav .HeaderModule_BlockA {
  display: flex;
}

.HeaderModule--compact-menu-fits
  .HeaderModule__Sticky:not(.HeaderModule__Sticky--hamburger-nav)
  .MainMenu__Wrapper--horizontal {
  display: flex;
}

.HeaderModule__BlockC {
  display: flex;
  margin-top: auto;
}

.HeaderModule__BlockC .LanguageMenu {
  display: none;
}

.CompactHeaderBar {
  .LanguageMenu {
    padding-right: 1em;
    padding-left: 1em;
    display: flex;
    align-items: center;
  }
}

.HeaderModule__Inner--top-in-header-nav,
.HeaderModule__Inner--top-below-header-nav {
  .HeaderModule__BlockC .LanguageMenu {
    display: flex;
  }
}

.HeaderModule__Inner--top-in-header-nav .HeaderModule__BlockC {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.HeaderModule__Inner--top-below-header-nav .LanguageMenu {
  position: absolute;
  left: 0;
  top: 0;
}

.HeaderModule__Inner--top-below-header-nav
  .HeaderModule__Logo
  + .HeaderModule__BlockF
  .LanguageMenu {
  position: static;
}

.HeaderModule__Inner--top-in-header-nav
  .HeaderModule__Logo
  + .HeaderModule__BlockF
  .HeaderModule__BlockC {
  flex-direction: column-reverse;
  align-items: flex-end;
}

.HeaderModule__Inner--top-below-header-nav
  .HeaderModule__Logo
  + .HeaderModule__BlockF
  .HeaderModule__BlockC {
  flex-direction: column;
  align-items: flex-end;
}

.HeaderModule--compact-menu-fits {
  .CompactHeaderBar .LanguageMenu {
    position: static;
  }
}

.HeaderModule__Sticky--fixed {
  position: fixed;
}

.HeaderModule__Sticky {
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: transform 250ms;

  .MainMenu__Wrapper {
    font-size: 0.9em;
  }

  .HeaderModule__Logo,
  .LanguageMenu {
    display: none;
  }
}

.HeaderModule--compact-menu-fits {
  .HeaderModule__Sticky {
    .HeaderModule__Logo,
    .LanguageMenu {
      display: flex;
    }
  }
}

.HeaderModule:not(.HeaderModule--compact-menu-fits)
  .HeaderModule__Sticky
  .CompactHeaderBar {
  background-color: transparent;
}

.CompactHeaderBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));

  .HeaderModule__Logo {
    padding: 0.2em 1em;
  }
}

.HeaderModule__Sticky--exiting,
.HeaderModule__Sticky--exited {
  transform: translateY(-100%);
  transform: translate3d(0, -100%, 0);

  .CompactHeaderBar {
    filter: none;
  }
}

.HeaderModule--compact-menu-fits .HeaderModule__Sticky__HamburgerMenu {
  display: none;
}

.HeaderModule__Sticky__HamburgerMenu {
  margin-right: auto;
}

.HeaderModule__LanguageItem a {
  padding: 0.5em;
}

.SplitMainMenu__Container {
  display: flex;
  align-items: center;
  padding: 0 2%;
}

.SplitMainMenu__Side {
  display: flex;
  width: 6em;
}

.SplitMainMenu__Side--right {
  justify-content: flex-end;
}

.SplitMainMenu__OuterWrapper {
  display: flex;
  flex: 1;
  opacity: 0;
  transition: opacity 0.25s;
}

.SplitMainMenu__OuterWrapper--is-distributed {
  opacity: 1;
}

.SplitMainMenu__PartnerLogo.SplitMainMenu__PartnerLogo {
  display: block;
  margin: 0;
}

.HeaderActionBottomBar,
.SplitMainMenu--bottom-bar {
  bottom: 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.SplitMainMenu--top-bottom-bars {
  top: 0;

  .MainMenu__ListLevel1 {
    top: 100%;
  }
}

.SplitMainMenu--bottom-bar {
  .MainMenu__ListLevel1 {
    bottom: 100%;
  }
}

.SplitMainMenu {
  position: absolute;
  z-index: 20;
  left: 0;
  right: 0;

  .MainMenu__ListItemLevel0 {
    display: flex;

    .MainMenu__Item__Text {
      display: flex;
      white-space: nowrap;
    }
  }

  .MainMenu__ListItemLevel0:hover .MainMenu__ItemLevel0,
  .MainMenu__Item--active {
    text-decoration: underline;
  }

  .MainMenu__ListLevel2up {
    padding-left: 0.5em;
  }

  .MainMenu__Item {
    padding: 0.5em 0.8em;
    display: flex;
    align-items: center;
  }

  .MainMenu__ItemLevel2up {
    padding: 0.3em 0.8em;
  }

  .MainMenu__ListLevel0 {
    font-size: 1em;
  }

  .MainMenu__ListLevel1 {
    display: none;
    min-width: 100%;
    position: absolute;
    z-index: 20;
    padding: 0.5em;
    left: 0;
  }

  .MainMenu__ListItemLevel0:hover .MainMenu__ListLevel1 {
    display: block;
  }
}

.SplitMainMenu .HeaderModule__Logo {
  padding: 0.7em 2em;
  min-width: 5em;
}

@include mq(xxlarge) {
  .SplitMainMenu .HeaderModule__Logo {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .SplitMainMenu .MainMenu__ListLevel0 {
    font-size: 1.1em;
  }
}

.SplitMainMenu__FakeWrapper {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  z-index: -50;
  width: 1px;
}

.SplitMainMenu__FakeList.SplitMainMenu__FakeList {
  width: 5000px;
}

.SplitMainMenu__List {
  display: flex;
  width: 100%;
  transition: opacity 0.5s;
}

.SplitMainMenu__Wrapper {
  // Force the left and right menu parts to
  // have the same widths, otherwise the
  // menu items distribution logic doesn’t work correctly
  flex-basis: calc(50% - 5em);
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
}

@include mq(xlarge) {
  .SplitMainMenu__Wrapper {
    flex-basis: calc(50% - 6em);
  }
}

@include mq(xxlarge) {
  .SplitMainMenu__Wrapper {
    flex-basis: calc(50% - 7.5em);
  }
}

.SplitMainMenu__List--left {
  justify-content: flex-end;
}

.SplitMainMenu__List--right {
  justify-content: flex-start;
}

.HeaderModule--menu-position-bottom-bar,
.HeaderModule--fixed-sidebar,
.HeaderModule--full-overlay-nav {
  .FullImageContainer::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    pointer-events: none;
  }
}

.HeaderModule--full-overlay-nav {
  .ImageOverlay.FlexAlignV--center {
    padding-left: 8em;
    padding-right: 8em;
  }

  .ImageOverlay.FlexAlignV--top {
    padding-top: 13em;
    padding-left: 14em;
    padding-right: 14em;
  }

  .FullImageContainer::after {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.15) 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.15) 75%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
}

.HeaderModule--menu-position-bottom-bar {
  .ImageOverlay {
    padding-bottom: 7em;
  }

  .ImageOverlay.FlexAlignV--top {
    padding-left: 13em;
    padding-right: 13em;
  }

  .FullImageContainer::after {
    background: linear-gradient(
      90deg,
      transparent 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
}

.HeaderModule--fixed-sidebar {
  .ImageOverlay {
    padding-top: 9em;
  }

  .ImageOverlay.FlexAlignH--center {
    margin-right: 5em;
  }

  .FullImageContainer::after {
    background: linear-gradient(0deg, transparent 50%, rgba(0, 0, 0, 0.8) 100%);
  }
}

.HeaderModule--menu-position-top-bottom-bars .ImagesModule {
  margin: 0 2%;
}

@include mq(large) {
  .HeaderModule--menu-position-top-bottom-bars {
    .ImageOverlay {
      padding-top: 6em;
      padding-bottom: 6em;
    }
  }
}

.HeaderSidebar {
  position: fixed;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  width: 5em;

  .LanguageMenu {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .MainMenu__ListLevel0 {
    font-size: 1.15em;
  }
}

.HeaderModule__MainMenuHamburgerCaption {
  font-size: 1.6em;
  padding: 0 1em 0.5em;
}

.Page__Preview {
  .HeaderSidebar {
    left: 28em;
    top: 9.3em;
    bottom: 0.5em;
  }
}

.HeaderSidebar__MenuButtonWrapper {
  display: flex;
  justify-content: center;
}

.HeaderSidebar__MenuButton {
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderSidebar__MenuIcon {
  font-size: 1.6em;
}

@keyframes sidebarMenuFadeIn {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.HeaderSidebar__MenuWrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  left: 100%;
  bottom: 0;
  animation: sidebarMenuFadeIn 0.25s both;
}

.HeaderSidebar__Menu {
  height: 100%;
  max-height: 100%;
  padding: 1.3em 0;
}

.HeaderModule__LogosWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 5% 0 calc(5% - 5em);
  z-index: 20;
  display: flex;
  justify-content: center;

  .HeaderModule__Logo {
    background-color: #fff;
  }

  .PartnerLogo {
    margin: 0;
  }
}

.HeaderModule__LogosWrapper--with-partner-logo::before {
  content: "";
  flex: 1;
}

.HeaderModule__LogosWrapperPartner {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1em 0;
}

.FullHeaderOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3%;
  // Is necessary because otherwise the buttons/links
  // in the images overlay wouldn’t be clickable
  pointer-events: none;

  .HeaderModule__Logo {
    position: absolute;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    padding: 0.5em;
  }

  .ContactNav__Item:hover {
    text-decoration: underline;
  }
}

.FullHeaderOverlay__Inner {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  // Re-enable click for direct children again
  > * {
    pointer-events: auto;
  }
}

.FullHeaderOverlay__Box {
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 1.2em;

  .ContactNav {
    display: block;
    padding: 0;
  }

  .LanguageMenu {
    padding-top: 0;
  }

  .ContactNav__ListItem {
    display: list-item;
  }
}

.FullHeaderOverlay__Box--absolute {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
}

.FullHeaderOverlay__MenuButton {
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  text-transform: uppercase;
}

.FullHeaderOverlay__MenuButtonInner {
  border-radius: 50%;
  width: 4.5em;
  height: 4.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
}

.FullHeaderOverlay__MenuButtonIcon {
  font-size: 2.2em;
  top: 0;
  fill: currentColor;
}

.FullHeaderOverlay__MenuButtonText {
  display: block;
  font-size: 1.3em;
  text-align: center;
  margin-top: 0.5em;
}

.FullHeaderMenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3% 3% 1.5%;
  z-index: 40;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);

  .MainMenu__ItemLevel0 > .MainMenu__Item__Text::after {
    content: "";
    display: block;
    height: 2px;
    background-color: #fff;
    width: 100%;
    position: relative;
    margin: 0.5em 0;
  }

  .MainMenu__ListLevel0 {
    font-size: 1.3em;
    display: flex;
    flex-wrap: wrap;
  }

  .MainMenu__ItemLevel0.MainMenu__Item--active,
  .MainMenu__ItemLevel0:hover {
    color: #a2a2a2;
  }

  .MainMenu__ItemLevel1 {
    padding-left: 0;
    padding-right: 0;
  }

  .MainMenu__ItemLevel0 {
    font-size: 1.7em;
  }

  .MainMenu__ItemLevel1 {
    font-size: 1.1em;
  }

  .MainMenu__ItemLevel2up {
    font-size: 1em;
    padding-left: 1em;
    padding-right: 0;
  }

  .MainMenu__ListItemLevel0 {
    margin-bottom: 2em;
    width: 100%;
    padding: 0.5em 1em;
  }

  .ActionModuleNavigation {
    font-size: 1.3em;
    padding-top: 2em;
  }

  .ActionModuleNavigation__Item {
    color: #fff;
    background: transparent;
    border: 2px solid;
    font-size: 1.1em;
    transition: opacity 0.25s;

    &:hover {
      opacity: 0.75;
    }
  }

  .ActionModuleNavigation__Item:not(:first-child) {
    margin-left: 1em;
  }
}

@include mq(large) {
  .FullHeaderMenu {
    .MainMenu__ListItemLevel0 {
      width: percentage(1 / 2);
    }
  }
}

@include mq(xlarge) {
  .FullHeaderMenu {
    .MainMenu__ListItemLevel0 {
      width: percentage(1 / 3);
    }
  }
}

@include mq(xxlarge) {
  .FullHeaderMenu {
    .MainMenu__ListItemLevel0 {
      width: percentage(1 / 4);
    }
  }
}

@include mq(xxxlarge) {
  .FullHeaderMenu {
    .MainMenu__ListItemLevel0 {
      width: percentage(1 / 5);
    }
  }
}

.FullHeaderMenu__Inner {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.FullHeaderMenu__Container {
  width: 100%;
  height: 100%;
  padding-left: 7em;
  overflow: auto;
}

.FullHeaderMenu__MenuWrapper {
  display: flex;
  align-items: center;
  min-height: 100%;
  justify-content: center;
}

.FullHeaderMenu__MenuWrapper--multi-level {
  align-items: flex-start;
}

.HeaderSidebarIconNav {
  list-style: none;
  padding: 0;
  margin: auto 0;
}

.HeaderSidebarIconNav__ListItem {
  position: relative;

  &:hover {
    .HeaderSidebarIconNav__Icon {
      transform: translateX(0.25em);
    }

    .HeaderSidebarIconNav__Text {
      display: block;
    }
  }
}

.HeaderSidebarIconNav__Item {
  padding: 1em 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
}

.HeaderSidebarIconNav__Icon {
  top: 0;
  font-size: 1.4em;
  transition: transform 0.2s;
}

.HeaderSidebarIconNav__Text {
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  padding: 0.3em 0.7em;
  border-radius: 0.5em;
  margin-left: 2px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.2em 0.6em 0.2em 0;
    border-color: transparent;
    border-right-color: #000;
    left: -0.6em;
    top: 50%;
    transform: translateY(-50%);
  }
}

.HeaderActionBottomBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 0 2%;
  align-items: center;
  z-index: 10;
}

.HeaderActionBottomBar__List {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
}

.HeaderActionBottomBar__LinkItem {
  color: inherit;
  display: block;
  padding: 1em;
  font-size: 1.3em;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.8;
  }
}

.HeaderActionBottomBar__Icon {
  fill: currentColor;
  margin-right: 0.25em;
}

.HeaderModule__InnerOverlay--split-menu {
  position: absolute;
  right: 2%;
  top: 1em;
  z-index: 10;
  font-size: 1.2em;
}

.HeaderActionLinks {
  text-align: right;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.HeaderActionLinks__List {
  list-style: none;
  margin: 0;
  padding: 0;
}

.HeaderActionLinks__LinkItem {
  color: inherit;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.8;
  }
}

.HeaderActionLinks__Icon {
  fill: currentColor;
  margin-right: 0.25em;
}

.MainMenu__List--parent-item-repeated {
  .MainMenu__ItemLevel1 {
    padding-left: 1.9em;
    padding-right: 1.9em;
  }

  .MainMenu__ItemLevel2up {
    padding-left: 2.4em;
    padding-right: 2.4em;
  }

  .MainMenu__ListItemLevel1:first-child .MainMenu__ItemLevel1 {
    padding-left: 1.6em;
    padding-right: 1.6em;

    .MainMenu__Item__Text {
      font-size: 1.1em;
    }
  }
}

.MainMenu__Item__Text {
  display: inline-block;
}
