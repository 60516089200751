.NewsletterModule {
  padding: 4em 0;
}

.NewsletterModule__Field {
  width: calc(50% - 2em);
  margin-top: 1em;
  margin-left: 2em;
}

.NewsletterModule__Form {
  margin: 0 auto;
}

.NewsletterModule__Form--narrow {
  max-width: 30em;
}

.NewsletterModule__Form--fraction-width-5 .NewsletterModule__Field {
  width: calc((100% / 5) - 2em);
}

.NewsletterModule__Form--fraction-width-4 .NewsletterModule__Field {
  width: calc((100% / 4) - 2em);
}

.NewsletterModule__Form--fraction-width-3 .NewsletterModule__Field {
  width: calc((100% / 3) - 2em);
}

.NewsletterModule__Form--fraction-width-2 .NewsletterModule__Field {
  width: calc((100% / 2) - 2em);
}

// Visually hidden
.NewsletterModule__FieldLabel {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.NewsletterModule__FieldLabel--required::after {
  content: " *";
}

.NewsletterModule__FieldInput {
  width: 100%;
  border: 0;
  background-color: transparent;
  color: inherit;
  padding: 0.5em;
  height: 2.5em;

  &::placeholder {
    color: currentColor;
  }
}

.NewsletterModule__FieldInput--with-border {
  border-bottom: 1px solid;
}

.NewsletterModule__MainFieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2em;
}

.NewsletterModule__Form--single-line .NewsletterModule__MainFieldset {
  justify-content: center;
}

.NewsletterModule__SubmitContainer {
  text-align: center;
}

.NewsletterModule__Message {
  padding: 1em 0;
  text-align: center;
}

.NewsletterModule__PrivacyMessage {
  text-align: center;
  max-width: 43em;
  margin: 2em auto 1em;
  font-size: 0.9em;
}
