.Btn {
  background: #313541;
  color: #fff;
  fill: #fff;
  padding: 1em;
  display: inline-block;
  transition: 0.2s background;
  font-size: 0.9em;
  line-height: 1.5;
  text-align: center;
}

.Btn--compact {
  padding: 0.75em;
}

.Btn--expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.Form__Content--radios .Btn {
  margin: 1em;
}

.Form__Content--radios-dense .Btn {
  margin: 0.3em;
}

.Btn--bare {
  border: 0;
  background: transparent;
  outline: 0;
  color: inherit;
  display: block;
  text-align: left;
  padding: 0;
  font-size: 0.9em;
}

.Btn--action.Btn--action,
.Btn--active.Btn--active,
.Btn:hover {
  background: #ffcc00;
  color: #47535b;
  fill: #47535b;
}

label.Btn input {
  display: none;
}

.LinkBtn {
  background: none;
  padding: 0;
  display: inline;
  color: inherit;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
}

.BtnGroup {
  display: flex;
  justify-content: space-between;

  .Btn {
    display: flex;
    align-items: center;
    justify-content: center;

    // Remove misalignment of icons
    .Icon {
      top: 0;
    }
  }

  .Btn:not(:first-child) {
    margin-left: 4px;
  }
}

.BtnGroup--full-width .Btn {
  flex: 1;
}

.BtnGroup--stretch {
  justify-content: center;

  .Btn {
    flex: 1;
  }

  .Btn:only-child {
    flex-basis: 50%;
    flex-grow: 0;
  }
}

.Btn__Caption {
  font-size: 0.9em;
  margin-top: 0.8em;
}
