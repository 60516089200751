.Form {
  input:not([type="checkbox"]):not([type="radio"]),
  textarea,
  select,
  .RichEditor__Textarea {
    width: 100%;
    font-size: 0.95em;
    font-weight: 300;
    padding: 0.75em 1em;

    &::placeholder {
      color: #bebebe;
      font-style: italic;
    }
  }

  textarea,
  .RichEditor__Textarea {
    min-height: 4em;
    font-size: 0.9em;
    line-height: 1.5;
    resize: vertical;
  }
}

.Form__Content,
.Form input,
.Form textarea,
.Form select:not(.LanguageMenu__Select) {
  border: 1px solid #313541;
  background: #1f2532;
  color: #fff;
}

.Form__Content--wrap {
  flex-wrap: wrap;
}

.Form__Content--center {
  align-items: center;
}

.Form__Label {
  order: -1;
}

.Form__Label label {
  display: inline-block;
  font-size: 0.8em;
  font-weight: 300;
  background: #313541;
  letter-spacing: 0.1em;
  padding: 0.3em 1em;
  color: #fff;
}

.Form__Field:hover .Form__Label label,
input:focus + .Form__Label label,
textarea:focus + .Form__Label label,
.RichEditor--focused + .Form__Label label {
  background: #ffcc00;
  color: #47535b;
}

.Form__Field:hover .Form__Content,
.Form__Field:hover input,
.Form__Field:hover textarea,
.Form__Field:hover .RichEditor,
input:focus,
textarea:focus,
.RichEditor--focused {
  border-color: #ffcc00;
}

.Form__Field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
}

.Form__Field--compact {
  margin-bottom: 0;
}

.Form__Content {
  padding: 0.5em;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.Form__Content--align-left {
  justify-content: flex-start;
}

.Form__Content--block {
  display: block;
}

.Form__Content--column {
  flex-direction: column;
}

.Form__Content--compact {
  padding: 2px;
}

* + .Form__Content__Button {
  margin-top: 0.5em;
}

.Form__Error {
  font-size: 0.9em;
  margin-top: 0.2em;
  background: #d81414;
  color: #fff;
  padding: 0.3em;
  color: #fff;
}

.Form__Translation {
  background: #313541;
  padding: 0.3em 1em;
  font-size: 0.8em;
  font-weight: 300;
  font-style: italic;
}

.Form__Info {
  margin-top: 0.5em;
  font-size: 0.9em;
  color: #fff;
  fill: #fff;
}

.Form__Field--checkbox {
  position: relative;

  label {
    display: flex;
    align-items: baseline;

    // To prevent triggering a checkbox state change
    pointer-events: none;
  }
}

.Form__Field__Checkbox {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1.3em;
  opacity: 0;

  // Re-enabling possibility to click directly on checkbox
  pointer-events: auto;
}

.Form__Field__CheckboxIcon--checked.Form__Field__CheckboxIcon--checked {
  display: none;
}

.Form__Field__Checkbox:checked ~ .Form__Field__CheckboxIcon {
  display: none;
}

.Form__Field__Checkbox:focus ~ .Form__Field__CheckboxIcon {
  fill: #ffcc00;
}

.Form__Field__Checkbox:checked + .Form__Field__CheckboxIcon--checked {
  display: inline-block;
}

.Form__Field__CheckboxIcon {
  fill: #fff;
  margin-right: 0.35em;
  flex: 0 0 1.3em;
}

.Form__Fieldset {
  padding: 0;
  border: 0;

  legend {
    margin-bottom: 1em;
    font-size: 1.1em;
  }
}

.Form__Table {
  td,
  th {
    padding: 0.25em 0.45em;
  }

  td:not(:first-child) {
    text-align: center;
  }
}

.Form__InlineFieldContent {
  display: flex;
  label {
    background-color: #1b1c25;
    border-right: 0;
    flex-basis: 0%;
    padding-right: 0.25em;
    font-size: 0.95em;
  }
}

.Form .Form__InlineFieldContent.Form__InlineFieldContent input {
  padding-left: 0.25em;
  border-left: 0;
}

.Form__RadioField {
  display: none;
}

.Form__RadioLabel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Form__RadioField:checked + .Form__RadioLabel {
  background: #ffcc00;
}
