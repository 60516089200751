.ImageOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-size: 0.5em;
  pointer-events: none;
  z-index: 10;
}

.ImageOverlay--padding-large {
  padding: 3% 8%;
}

.ImageOverlay--padding-small {
  padding: 3%;
}

@include mq(small) {
  .ImageOverlay {
    font-size: 0.6em;
  }
}

@include mq(medium) {
  .ImageOverlay {
    font-size: 0.8em;
  }
}

@include mq(large) {
  .ImageOverlay {
    font-size: 1em;
  }
}

.ImageOverlay__Text {
  color: #fff;
  line-height: 1.3;
}

.ImageOverlay__Text--background {
  line-height: 1.45;
}

.ImageOverlay__Text--plain {
  text-shadow: 0 0 0.2em rgba(0, 0, 0, 0.35);
}

.ImageOverlay__Inner {
  pointer-events: auto;
}

.ImageOverlay__Inner--size-small,
.ImageOverlay__Inner--size-medium,
.ImageOverlay__Inner--size-big {
  font-size: 1em;
}

@include mq(medium) {
  .ImageOverlay__Inner--size-small {
    font-size: 1em;
  }

  .ImageOverlay__Inner--size-medium {
    font-size: 1.1em;
  }

  .ImageOverlay__Inner--size-big {
    font-size: 1.2em;
  }
}

@include mq(large) {
  .ImageOverlay__Inner--size-small {
    font-size: 1em;
  }

  .ImageOverlay__Inner--size-medium {
    font-size: 1.3em;
  }

  .ImageOverlay__Inner--size-big {
    font-size: 1.6em;
  }
}

.ImageOverlay__Subtitle {
  margin-top: 0.5em;
}

.ImageOverlay__Text--offset-right {
  transform: translateX(-7px);
}

.ImageOverlay__Text--offset-left {
  transform: translateX(7px);
}
