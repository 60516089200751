.Nav {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline;
  }

  li + li:before {
    content: "|";
    padding: 0 0.3em;
  }

  a {
    color: inherit;
  }

  a:hover {
    text-decoration: underline;
  }

  .Icon {
    margin-right: 0.2em;
    position: relative;
    top: 0.2em;
  }
}

.List {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.5;

  li {
    position: relative;

    &:not(:first-child) {
      margin-top: 0.3em;
    }
  }
}

.List--with-bullets li {
  padding-left: 2em;

  .List__BulletIcon {
    position: absolute;
    top: 0.2em;
  }
}

.List__BulletIcon {
  fill: #fff;
  border-radius: 50%;
  background: #b79f73;
  margin-right: 0.4em;
  left: 0;
}

.List--columns {
  width: 100%;

  li {
    width: 100%;
    break-inside: avoid;
  }
}

@include mq(xlarge) {
  .List--columns {
    column-count: 3;
  }
}
