.ReadMoreCollapse {
  position: relative;
}

.ReadMoreCollapse__Measure {
  position: absolute;
  pointer-events: none;
}

.ReadMoreCollapse__Inner {
  transition: height 0.5s;
  overflow: hidden;
  position: relative;
}

.ReadMoreCollapse__Inner--collapsed::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, currentColor 0%, transparent 3em);
  pointer-events: none;
  z-index: 10;
}

.ReadMoreCollapse__Inner--open::after {
  display: none;
}

.ReadMoreCollapse__BtnContainer {
  display: flex;
  justify-content: center;
  padding: 1.5em 0.5em 0.5em;
}

.ReadMoreCollapse__Btn {
  font-size: 1.5em;
  border: 1px solid;
  border-radius: 50%;
  fill: currentColor;
}

.ReadMoreCollapse__Icon {
  display: block;
  top: 0;
}
