.LeftHeaderOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3%;
  pointer-events: none;

  .HeaderModule__Logo {
    background-color: #fff;
    padding: 0.5em;
  }

  .ImagesModule {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.LeftHeaderOverlay--show-menu-separators
  .MainMenu__ListItemLevel0:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.LeftHeaderOverlay__Box {
  pointer-events: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LeftHeaderOverlay__BoxInfo {
  text-align: right;
  padding: 2em;
}

.HeaderModule--left-overlay-nav {
  .ContentSlider__Control {
    transform: none;
    bottom: 0;
    top: auto;
    font-size: 3em;
  }

  .ContentSlider__Control--prev {
    transform: translateX(-100%);
  }

  // Double class selector necessary because of the container queries class
  .SliderModule.SliderModule .ContentSlider__Control {
    left: 50%;
  }

  .ImageOverlay {
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 7em;
  }

  .FullImageContainer::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 30%,
      transparent 50%,
      rgba(0, 0, 0, 0.1) 80%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
}

@include mq(large) {
  .HeaderModule--left-overlay-nav .ContentSlider__Control {
    margin: 0;
  }
}

.LeftOverlayNav {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3em;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  transition: background-color 0.25s;

  > * {
    pointer-events: auto;
  }

  .Icon {
    fill: currentColor;
  }

  .MainMenu__ListItemLevel0 {
    padding: 0.5em 0;
    position: static;
  }

  .MainMenu__ItemLevel0 {
    font-size: 1.3em;
    padding: 0.25em 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  }

  .MainMenu__Item {
    transition: filter 0.15s;
  }

  .MainMenu__Item:hover,
  .MainMenu__Item--active {
    filter: brightness(80%);
  }
}

@include mq(xxlarge) {
  .LeftOverlayNav .MainMenu__ItemLevel0 {
    font-size: 1.5em;
  }
}

.LeftOverlayNav__Icon {
  margin-left: 1em;
  font-size: 0.7em;
}

.LeftOverlayNav__Btn {
  cursor: pointer;
  font-size: 1em;
  display: block;
  width: 100%;
}

.LeftOverlayNav__BackBtn {
  font-size: 1.3em;
  padding: 0.4em 0.2em;
  margin-right: 0.5em;
}

.LeftOverlayNav__MenuContainer {
  transition: transform 0.2s, opacity 0.2s;
  max-width: 25%;
}

.LeftOverlayNav__MenuContainer-enter {
  opacity: 0;
  transform: translate3d(-5%, 0, 0);
}

.LeftOverlayNav__MenuContainer-enter-active,
.LeftOverlayNav__MenuContainer-exit {
  opacity: 1;
  transform: translate3d(0%, 0, 0);
}

.LeftOverlayNav__MenuContainer-exit-active {
  opacity: 0;
  transform: translate3d(5%, 0, 0);
}

.LeftOverlayNav__Submenu {
  display: flex;
  align-items: flex-start;
  margin-left: -1em;

  .MainMenu__ListItemLevel1 {
    padding: 0.4em 0;
  }

  .MainMenu__ItemLevel0 {
    font-size: 1.6em;
  }

  .MainMenu__ItemLevel1 {
    padding: 0.15em 0;

    > .MainMenu__Item__Text {
      font-size: 1.15em;
    }
  }

  .MainMenu__ItemLevel2up {
    padding: 0.15em 0.5em;
    margin: 0.4em 0;

    > .MainMenu__Item__Text {
      font-size: 1em;
    }
  }
}

@include mq(xxlarge) {
  .LeftOverlayNav__Submenu {
    .MainMenu__ItemLevel0 {
      font-size: 1.7em;
    }

    .MainMenu__ItemLevel1 > .MainMenu__Item__Text {
      font-size: 1.2em;
    }

    .MainMenu__ItemLevel2up > .MainMenu__Item__Text {
      font-size: 1.1em;
    }
  }
}
