// A mixin for media queries

@import "mq-settings";

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      // :root to achieve the same CSS specifity than mq-container-queries
      :root {
        @content;
      }
    }
  } @else {
    @warn "Unfortunately,
      no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}
