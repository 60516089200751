.ImageGalleryModule__Wrapper {
  overflow: hidden;
  margin-top: -1.3%;
}

.ImageGalleryModule__Item {
  float: left;
  margin-top: 1.3%;
  margin-left: 1.2%;
  width: 24.1%;
}

.ImageGalleryModule__Item:nth-child(1),
.ImageGalleryModule__Item:nth-child(4n + 6) {
  margin-left: 0;
}

.ImageGalleryModule__Item:nth-child(2) {
  width: 74.7%;
}

.ImageGalleryModule__ButtonWrap {
  justify-content: center;
  display: flex;
  margin-top: 1em;
}

.ImageGalleryModule__Icon {
  top: 0;
  font-size: 3.5em;
  display: block;
  fill: currentColor;
}
