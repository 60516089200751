.WeatherWebcamModule {
  padding: 4em 0;

  .Bs-Weather {
    padding-left: 0;
    padding-right: 0;
  }
}

.WeatherWebcamModule__Inner {
  display: flex;
  flex-direction: column;
}

.WeatherWebcamModule__Inner--horizontal {
  max-width: calc(1920px + 3%);

  .WeatherWebcamModule__Webcams {
    width: 30%;
    border-left: 1px solid #e6e6e6;
    padding-left: 4%;
    margin-left: 4%;
  }
}

@include mq(large) {
  .WeatherWebcamModule__Inner--horizontal {
    flex-direction: row;
  }

  .WeatherWebcamModule__Weather {
    width: 70%;
    flex: 1;
  }
}

.WeatherWebcamModule__Inner--vertical {
  flex-direction: column;

  .WeatherWebcamModule__Weather {
    width: 100%;
  }

  .WeatherWebcamModule__Webcams:not(:first-child) {
    margin-top: 2em;
  }
}

.WeatherWebcamModule__Placeholder {
  font-size: 1.6em;
}
