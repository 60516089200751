.TrustYou {
  width: 11em;
  position: relative;
  text-align: center;
  margin-bottom: 1.5em;
}

.TrustYou::before,
.TrustYou::after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.4em 1.4em 0 0;
  border-color: #ddd transparent transparent transparent;
  position: absolute;
  bottom: -1.4em;
  left: 0;
}

.TrustYou::after {
  border-color: #fff transparent transparent transparent;
  border-width: calc(1.4em - 1px) calc(1.4em - 1px) 0 0;
  bottom: calc(-1.4em + 2px);
  left: 1px;
}

.TrustYou__Content {
  padding: 0.1em 0.5em;
  border: 1px solid #ddd;
  border-bottom-right-radius: 0.3em;
  background-color: #fff;
}

.TrustYou__Grade {
  background: #3498db;
  color: #fff;
  padding: 0.2em 1em;
  text-transform: uppercase;
  font-weight: bold;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

.TrustYou__Logo {
  margin-top: 0.2em;

  img {
    width: 65%;
  }
}

.TrustYou__Rating {
  color: #7cb342;
  line-height: 1.2;
  font-size: 2em;
  font-weight: 600;
}

.TrustYou__Stars {
  fill: #7cb342;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.TrustYou__Ratings {
  font-size: 0.65em;
}
