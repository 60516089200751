.FooterModule__Top {
  padding: 3em 0;
  text-align: center;
  border-bottom: 1px solid #b79f73;

  .Logo + .Logo {
    margin-left: 4em;
  }
}

.FooterModule__Widgets {
  margin-top: 2em;
}

@include mq(large) {
  .FooterModule__Widgets {
    margin-left: 2em;
    margin-top: 0;
  }
}

.FooterModule__Main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b79f73;
  padding: 2em 0;
}

@include mq(large) {
  .FooterModule__Main {
    flex-direction: row;
  }
}

.FooterModule__Sub {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em 0;
}

@include mq(large) {
  .FooterModule__Sub {
    flex-direction: row;
  }
}

.FooterModule--big .FooterModule__Sub {
  justify-content: flex-end;
}

.FooterModule__Section {
  h4 {
    margin-bottom: 0.5em;
  }

  align-self: flex-start;

  * {
    color: inherit;
  }
}

@include mq(large) {
  .FooterModule__Section {
    white-space: nowrap;
  }
}

.FooterModule__Hgv img {
  vertical-align: middle;
  margin-left: 0.4em;
  height: 1.5em;
  width: auto;
}

.FooterModule__Nav {
  li {
    display: inline-block;
  }
}
