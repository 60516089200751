// Styles largely inspired by https://tobiasahlin.com/spinkit/

$spinner-size: 1em;
$spinner-color: #fff;

.Spinner {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}

.Spinner__Bounce {
  width: $spinner-size / 2;
  height: $spinner-size / 2;
  background-color: $spinner-color;
  border-radius: 100%;
  display: inline-block;
  animation: spinner-bounce-delay 1.4s infinite ease-in-out both;
}

.Spinner__Bounce--1 {
  animation-delay: -0.32s;
}

.Spinner__Bounce--2 {
  animation-delay: -0.16s;
}

@keyframes spinner-bounce-delay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
