.Icon {
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  line-height: 1;
  position: relative;
  top: 0.25em;
  text-align: center;

  > svg {
    display: block;
  }
}
