.InlineTerms {
  position: relative;
  border-top: 1px solid #565a5c;
}

.InlineTerms__Close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.4em;
  line-height: 3em;
  width: 3em;
  text-align: center;
  background: #565a5c;
  color: #fff;
  padding: 0;
  fill: #fff;
}
