.SeparatorModule {
  padding: 1em 0;
}

.SeparatorModule__Icon {
  top: 0;
  display: flex;
  width: auto;
  height: auto;

  svg {
    width: 4em;
    height: 4em;
  }
}

.SeparatorModule__IconWrap {
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.SeparatorModule__IconWrap--margins {
  margin: 0 3em;
}

.SeparatorModule__IconWrap--size-small {
  font-size: 0.4em;
}

.SeparatorModule__IconWrap--size-medium {
  font-size: 0.6em;
}

.SeparatorModule__IconWrap--size-big {
  font-size: 0.8em;
}

.SeparatorModule__Icon:not(:first-child) {
  margin-left: 3em;
}

@include mq(large) {
  .SeparatorModule__IconWrap--size-small {
    font-size: 0.6em;
  }

  .SeparatorModule__IconWrap--size-medium {
    font-size: 0.8em;
  }

  .SeparatorModule__IconWrap--size-big {
    font-size: 1em;
  }
}

.SeparatorModule__Line {
  position: relative;
  flex: 1;
  background-position: center center;
  background-repeat: repeat-x;
  min-height: 22px;
}

.SeparatorModule__Line--style-straight::before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 0;
  content: "";
  background-color: currentColor;
}

.SeparatorModule__Line--right {
  background-position: left center;
}

.SeparatorModule__Line--left {
  background-position: right center;
}

.SeparatorModule__Line--weight-light::before {
  height: 1px;
}

.SeparatorModule__Line--weight-strong::before {
  height: 2px;
}

.SeparatorModule__Container {
  display: flex;
  fill: currentColor;
  justify-content: center;
}

.SeparatorModule__Container--width-narrow {
  max-width: 40em;
}

.SeparatorModule--height-small {
  padding: 0.5em 0;
}

.SeparatorModule--height-medium {
  padding: 1em 0;
}

.SeparatorModule--height-big {
  padding: 1.6em 0;
}

@include mq(large) {
  .SeparatorModule--height-small {
    padding: 1em 0;
  }

  .SeparatorModule--height-medium {
    padding: 2.5em 0;
  }

  .SeparatorModule--height-big {
    padding: 4em 0;
  }
}
