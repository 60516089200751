.EasiPayModule {
  padding: 2em 0;
}

.EasiPayModule__Content--empty {
  background: url("/public/easipay.svg") no-repeat center center;
  height: 8em;

  > * {
    display: none;
  }
}
