.TextModule {
  text-align: center;
  display: flex;
}

.TextModule__Wrapper {
  width: 100%;
  display: flex;
}

.TextModule__WrapperInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  flex: 1;
}

@include mq(medium) {
  .TextModule__WrapperInner {
    flex-direction: row;
  }
}

.FooterModule .TextModule__Wrapper {
  padding-left: 0;
  padding-right: 0;
}

.TextModule__WrapperInner--narrow {
  max-width: 50em;
}

.TextModule__WrapperInner--image-right {
  flex-direction: column-reverse;

  .TextModule__Image {
    margin-top: 2em;
  }
}

@include mq(medium) {
  .TextModule__WrapperInner--image-right {
    flex-direction: row-reverse;

    .TextModule__Image {
      margin-top: 0;
    }
  }
}

.TextModule__WrapperInner--image-left .TextModule__Image {
  margin-bottom: 1em;
}

@include mq(medium) {
  .TextModule__WrapperInner--image-left .TextModule__Image {
    margin-bottom: 0;
  }
}

.TextModule__Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // Fix for IE 11
  &:only-child {
    flex-basis: auto;
  }
}

@include mq(medium) {
  // Fix for IE 11
  .TextModule__Content:only-child {
    flex-basis: 100%;
  }

  .TextModule__Content:not(:only-child) {
    width: 56%;
  }
}

.TextModule__Image {
  width: 100%;
}

@include mq(medium) {
  .TextModule__Image {
    width: 40%;
  }
}

.TextModule__Description {
  font-size: 1.1em;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1rem 0;
  }
}

@include mq(medium) {
  .TextModule__Description--columns-2 .DraftEditor-editorContainer {
    columns: 2;
    column-gap: 2em;
  }
}
