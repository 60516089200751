.Google360VirtualTourModule {
  padding: 4em 0;
}

.Google360VirtualTourModule__Iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
}

.Google360VirtualTourModule__Iframe--no-click {
  pointer-events: none;
}

.Google360VirtualTourModule__AspectRatioContainer::before {
  padding-top: 140%;
}

@include mq(small) {
  .Google360VirtualTourModule__AspectRatioContainer::before {
    padding-top: 120%;
  }
}

@include mq(medium) {
  .Google360VirtualTourModule__AspectRatioContainer::before {
    padding-top: 75%;
  }
}

@include mq(large) {
  .Google360VirtualTourModule__AspectRatioContainer::before {
    padding-top: 56.25%;
  }
}

.Google360VirtualTourModule__Empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2.5em;
  opacity: 0.5;
}
