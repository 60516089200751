.SliderModule {
  position: relative;

  .ContentSlider__Control {
    background-color: transparent;
    font-size: 2em;
    filter: drop-shadow(0 0 0.02em rgba(0, 0, 0, 0.5));
    transition: filter 0.25s;

    &:focus {
      outline: 0;
      filter: drop-shadow(0 0 0.02em rgba(0, 0, 0, 0.5)) brightness(80%);
    }
  }

  .ContentSlider__Control--prev {
    left: 0;
  }

  .ContentSlider__Control--next {
    right: 0;
  }
}

.SliderModule__Slide {
  position: relative;

  // react-slick sets an inline-style which needs
  // to be overwritten with !important, see
  // https://github.com/akiran/react-slick/issues/1378
  display: block !important;
}

@include mq(medium) {
  .SliderModule .ContentSlider__Control {
    font-size: 4em;
  }
}

.ContentSlider__Control {
  background-color: #9b4341;
  border: 0;
  cursor: pointer;
  fill: #fff;
  height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.25s;
  width: 1em;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

@include mq(large) {
  .SliderModule {
    .ContentSlider__Control {
      font-size: 5.2em;
    }

    .ContentSlider__Control--prev {
      margin-left: 0.3em;
    }

    .ContentSlider__Control--next {
      margin-right: 0.3em;
    }
  }
}

.ContentSlider__Control__Icon {
  width: 1.3em;
  height: auto;
  position: static;
}

.ContentSlider__Control--prev {
  left: 1.3em;
}

.ContentSlider__Control--next {
  right: 1.3em;
}
