.VideoModule__Video {
  width: 100%;
  height: 100%;
  border: 0;
}

.VideoModule__Wrapper {
  padding: 1.5em 0;
}

@include mq(large) {
  .VideoModule__Wrapper {
    padding: 3em 0;
  }
}

.VideoModule__Wrapper--no-click {
  pointer-events: none;
}

.VideoModule__Wrapper--full-width {
  padding: 0;
  max-width: 100%;

  .VideoModule__Title {
    padding-top: 1.5em;
  }
}

.VideoModule__Wrapper--small {
  max-width: calc(1000px + 3%);
}

.VideoModule__AspectRatioContainer::before {
  padding-top: 75%;
}

@include mq(medium) {
  .VideoModule__AspectRatioContainer::before {
    padding-top: 56.25%;
  }
}
