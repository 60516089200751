.ModuleConsentBox {
  padding: 2em;
}

.ModuleConsentBox__Modal {
  padding: 3em;
  margin: 0 auto;
  max-width: 32em;
  color: #fff;
  background-color: #5b5c5c;
}

.ModuleConsentBox__Button--more {
  color: #565656;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}

.ModuleConsentBox__Button--accept {
  color: #e3e3e3;
  background-color: #0096ff;
  border-color: #0096ff;
}
