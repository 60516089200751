.MultiBox--list .MultiBox__Wrapper {
  display: flex;
  justify-content: center;
}

.MultiBox__Wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.MultiBox__WrapperOuter {
  flex: 0 0 100%;
  width: 100%;

  // min-width and min-height is necessary as a workaround for react-slick
  // not working correctly inside a flex container, see
  // https://github.com/akiran/react-slick/issues/1320#issuecomment-427359800
  min-height: 0;
  min-width: 0;
}

.MultiBox__Wrapper__Inner {
  display: flex;
  position: relative;

  // Set a minimum height to have an initial
  // area for scrollIntoView() if the
  // content is not yet loaded
  min-height: 2em;
}

.MultiBox--center .MultiBox__Wrapper__Inner {
  justify-content: center;
}

.MultiBox--list .MultiBox__Wrapper__Inner {
  flex-wrap: wrap;
  margin-top: -1.5em;
  margin-left: -1.5em;
}

.MultiBox__Item {
  background: #f0ece3;
  width: 100%;
}

.MultiBox--list .MultiBox__Item {
  margin-top: 1.5em;
  margin-left: 1.5em;
}

.MultiBox--slider .MultiBox__Item {
  height: 100%;
}

.MultiBox__Item__Inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@include mq(medium) {
  .MultiBox--horizontal .MultiBox__Item__Inner {
    flex-direction: row;
  }

  .MultiBox--vertical:not(.MultiBox--slider) {
    &.MultiBox--max-columns-count-1 .MultiBox__WrapperOuter {
      flex-basis: 50%;
    }

    &.MultiBox--max-columns-count-2 .MultiBox__Item,
    &.MultiBox--max-columns-count-3 .MultiBox__Item,
    &.MultiBox--max-columns-count-4 .MultiBox__Item {
      width: calc(50% - 1.5em - 0.1px);
    }
  }
}

@include mq(large) {
  .MultiBox__WrapperOuter--narrow {
    flex-basis: 85%;
    width: 85%;
  }

  .MultiBox--vertical:not(.MultiBox--slider) {
    &.MultiBox--max-columns-count-1 .MultiBox__WrapperOuter {
      flex-basis: percentage(1 / 3);
    }

    &.MultiBox--max-columns-count-3 .MultiBox__Item,
    &.MultiBox--max-columns-count-4 .MultiBox__Item {
      width: calc(#{percentage(1 / 3)} - 1.5em - 0.1px);
    }
  }

  .MultiBox--horizontal:not(.MultiBox--slider):not(.MultiBox--count-1):not(.MultiBox--max-columns-count-1)
    .MultiBox__Item:not(:only-child) {
    width: calc(50% - 1.5em - 0.1px);
  }
}

@include mq(xxlarge) {
  .MultiBox--vertical:not(.MultiBox--slider) {
    &.MultiBox--max-columns-count-4 .MultiBox__Item {
      width: calc(#{percentage(1 / 4)} - 1.5em - 0.1px);
    }
  }
}

.MultiBox__Item__Header {
  margin-bottom: 1.5em;
}

.MultiBox__Item__HeaderLink {
  color: inherit;
  display: block;
}

.MultiBox__Item__Title {
  margin-bottom: 0.4em;
  line-height: 1.2;
  color: #9b4341;
  font-size: 1.6em;
}

@include mq(medium) {
  .MultiBox__Item__Title {
    font-size: 1.4em;
  }
}

@include mq(large) {
  .MultiBox--max-columns-count-2 .MultiBox__Item__Title {
    font-size: 1.6em;
  }

  .MultiBox--max-columns-count-3,
  .MultiBox--max-columns-count-4 {
    .MultiBox__Item__Title {
      font-size: 1.3em;
    }
  }
}

@include mq(xlarge) {
  .MultiBox--max-columns-count-3 .MultiBox__Item__Title {
    font-size: 1.5em;
  }
}

@include mq(xxlarge) {
  .MultiBox--max-columns-count-3 .MultiBox__Item__Title {
    font-size: 1.7em;
  }

  .MultiBox--max-columns-count-4 .MultiBox__Item__Title {
    font-size: 1.4em;
  }
}

.MultiBox__Item__Subtitle {
  margin-top: 0.3em;
  font-size: 1.1em;
  font-weight: 600;
}

.MultiBox--horizontal .MultiBox__Item__Picture {
  flex: 1;
}

.MultiBox__Item__Content {
  flex-grow: 1;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MultiBox__Item__Bar:not(:empty) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@include mq(medium) {
  .MultiBox--horizontal .MultiBox__Item__Content {
    flex: 1;
    width: 50%;
  }
}

.MultiBox__Item__FromPrice {
  text-align: right;
  color: #9b4341;
  font-weight: 600;
  font-size: 1.5em;
  margin-top: 0.5em;
}

.MultiBox__NavigationWrapper {
  position: absolute;
  width: 100%;
  width: 94%;
}

.MultiBox__NavigationWrapper--prev {
  left: 0;
}

.MultiBox__NavigationWrapper--next {
  right: 0;
}

.MultiBox__NavigationWrapper--3 {
  width: calc(#{percentage(0.94 / 3)} - #{12px * 4 / 3});
}

.MultiBox__NavigationWrapper--2 {
  width: calc(#{percentage(0.94 / 2)} - 12px);
}

@include mq(medium) {
  .MultiBox--horizontal .MultiBox__NavigationWrapper {
    position: static;
    height: 0;
    &::before {
      display: none;
    }
  }
}

.MultiBox__Navigation {
  width: 2.5em;
  height: 2.5em;
  padding: 0;
  border: 0;
  z-index: 10;
  position: absolute;
  bottom: 3em;
}

@include mq(medium) {
  .MultiBox__Navigation {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

.MultiBox__Slider {
  flex: 1;
}

.MultiBox__Navigation--next {
  right: 0;
}

.MultiBox__Navigation--prev {
  left: 0;
}

@include mq(large) {
  .MultiBox__Navigation--next {
    right: 1%;
  }

  .MultiBox__Navigation--prev {
    left: 1%;
  }
}

.MultiBox__NavigationIcon {
  width: 2em;
  height: 2em;
  top: 0.15em;
}

.MultiBox__NavigationWrapper {
  display: flex;
  align-items: center;
}

$slide-gap: 1.5em;

.MultiBox .slick-slider:not(.slick-initialized) .slick-slide:not(:first-child) {
  margin-left: $slide-gap;
}

.MultiBox__Tile {
  width: 100%;
  position: relative;
  background-color: #15151e;
  line-height: 1.2;

  // necessary because it could be an <a> tag
  display: block;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1em;
    right: 1em;
    bottom: 1em;
    left: 1em;
    z-index: 3;
    border: 1px solid #fff;
  }
}

@include mq(medium) {
  .MultiBox--max-columns-count-2 .MultiBox__Tile,
  .MultiBox--max-columns-count-3 .MultiBox__Tile,
  .MultiBox--max-columns-count-4 .MultiBox__Tile {
    width: 50%;
  }
}

@include mq(large) {
  .MultiBox--max-columns-count-3 .MultiBox__Tile,
  .MultiBox--max-columns-count-4 .MultiBox__Tile {
    width: 33.33%;
  }
}

@include mq(xxlarge) {
  .MultiBox--max-columns-count-3 .MultiBox__Tile {
    width: 33.33%;
  }

  .MultiBox--max-columns-count-4 .MultiBox__Tile {
    width: 25%;
  }
}

.MultiBox__Tile__TitleOuter {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  color: #fff;
  z-index: 2;
  text-align: center;
}

.MultiBox__Tile__Title {
  font-size: 1.7em;
  padding: 1em;
}

@include mq(medium) {
  .MultiBox--max-columns-count-1 .MultiBox__Tile__Title {
    font-size: 2.7em;
  }

  .MultiBox--max-columns-count-2,
  .MultiBox--max-columns-count-3,
  .MultiBox--max-columns-count-4 {
    .MultiBox__Tile__Title {
      font-size: 1.6em;
    }
  }
}

@include mq(large) {
  .MultiBox--max-columns-count-1 .MultiBox__Tile__Title {
    font-size: 3.5em;
  }

  .MultiBox--max-columns-count-2 .MultiBox__Tile__Title {
    font-size: 2em;
  }

  .MultiBox--max-columns-count-3,
  .MultiBox--max-columns-count-4 {
    .MultiBox__Tile__Title {
      font-size: 1.5em;
    }
  }
}

@include mq(xlarge) {
  .MultiBox--max-columns-count-3 .MultiBox__Tile__Title {
    font-size: 1.8em;
  }

  .MultiBox--max-columns-count-4 .MultiBox__Tile__Title {
    font-size: 1.6em;
  }
}

@include mq(xxlarge) {
  .MultiBox--max-columns-count-4 .MultiBox__Tile__Title {
    font-size: 1.5em;
  }
}

.MultiBox--tiles {
  padding: 0;

  &.MultiBox--list .MultiBox__Wrapper__Inner {
    margin-top: 0;
    margin-left: 0;
  }
}
