.OfferingsModule {
  padding: 1em 0;

  .Bs-Heading {
    line-height: 1.4;
  }
}

@include mq(large) {
  .OfferingsModule {
    padding: 2em 0;
  }
}

.OfferingsModule__DetailsLoading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
}
