.Bs-Weather__Forecast__Heading,
.Bs-Weather__Forecast__Categories,
.Bs-Weather__Preview__Heading,
.Bs-Weather__Preview__Description,
.Bs-Weather__Provider {
  .WeatherModule--align-left & {
    text-align: left;
  }

  .WeatherModule--align-center & {
    text-align: center;
  }

  .WeatherModule--align-right & {
    text-align: right;
  }
}
