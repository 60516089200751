.SentresModule {
  padding: 4em 0;
}

.SentresModule__DefaultLogo {
  height: 8em;
  background: url("/public/sentres-logo.svg") no-repeat center center;
  margin-bottom: 2em;
}

.SentresModule__Iframe {
  border: 0;
  display: block;
  width: 100%;
  height: 800px !important;
  overflow: hidden;
}

.SentresModule__Iframe--no-click {
  pointer-events: none;
}

.SentresModule__Caption {
  margin-top: 1.5em;
}
