.PartnerLogos--compact-first {
  margin-bottom: 2em;
}

.PartnerLogos--compact-last {
  margin-top: 3em;
}

@include mq(large) {
  .PartnerLogos--compact-first {
    margin-right: 3em;
    margin-bottom: 0;
    max-width: 50%;
  }

  .PartnerLogos--compact-last {
    margin-left: 3em;
    margin-top: 0;
    flex: 1;
  }
}

.PartnerLogos__Inner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  margin-top: -2em;
  margin-left: -2em;
}

@include mq(small) {
  .PartnerLogos__Inner {
    flex-direction: row;
    align-items: flex-start;
  }
}

@include mq(large) {
  .PartnerLogos--compact-last .PartnerLogos__Inner {
    justify-content: flex-end;
  }
}

.PartnerLogo {
  margin-left: 2em;
  margin-top: 2em;
  height: 4em;

  img {
    max-width: 11em;
    max-height: 4em;
    // TODO: this probably only works with svg
    // and would distort raster images
    height: 100%;

    // CSS hack to only target IE 9–11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: auto;
    }
  }

  + .PartnerLogo {
    margin-left: 2em;
  }
}

.PartnerLogo--small {
  height: 2.8em;

  img {
    max-width: 9em;
    max-height: 2.8em;
  }
}

// CSS hack to target Microsoft Edge 12
// which displays distorted SVG images without it
@supports (-ms-accelerator: true) and (not (color: unset)) {
  .PartnerLogo img {
    width: 100%;
  }
}
