.ImagesModule--full-height {
  height: 100%;

  .FullImageContainer,
  .FullImage {
    height: 100%;
    object-fit: cover;
  }

  .SliderModule,
  .SliderModule__Slide,
  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }
}

.ImagesSeparator {
  display: flex;
  justify-content: center;
  height: 100%;
}

.ImagesSeparator__Inner {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.ImagesSeparator__Inner--width-1 {
  width: (100% * 1 / 6);
}

.ImagesSeparator__Inner--width-2 {
  width: (100% * 2 / 6);
}

.ImagesSeparator__Inner--width-3 {
  width: (100% * 3 / 6);
}

.ImagesSeparator__Inner--width-4 {
  width: (100% * 4 / 6);
}

.ImagesSeparator__Inner--width-5 {
  width: (100% * 5 / 6);
}

.ImagesSeparator__Item {
  width: 50%;
  position: relative;
  overflow: hidden;
}

.ImagesModule--full-height .ImagesSeparator__Item {
  height: 100%;
}

.ImagesSeparator--columns-1 .ImagesSeparator__Item {
  width: 100%;
}

.ImagesSeparator--columns-3 .ImagesSeparator__Item {
  width: (100% / 3);
}

.ImagesSeparator--columns-4 .ImagesSeparator__Item {
  width: 25%;
}

.ImagesSeparator--columns-5 .ImagesSeparator__Item {
  width: 20%;
}

.ImagesSeparator--columns-6 .ImagesSeparator__Item {
  width: (100% / 6);
}
