.ImagesCopyright__PictureList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -1em;
}

.ImagesCopyright__PictureListItem {
  width: 120px;
  margin: 1em 0 0 1em;
}

.ImagesCopyright__ListItem {
  margin-top: 1em;
}
