.ModuleWrapper--PopUpModule {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 27.95em;
  z-index: 70;

  &:empty {
    display: none;
  }
}

.ModuleWrapper--PopUpModule.ModuleWrapper--untranslated {
  display: none;
}

.PopUpModule {
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 70;
  padding: 0.5em;
  overflow: auto;
  height: 100%;
}

.PopUpModule--dark-background {
  background-color: rgba(0, 0, 0, 0.7);
}

@include mq(medium) {
  .PopUpModule {
    padding: 2em 0;
  }
}

.ModuleWrapper--PopUpModule .PopUpModule {
  position: absolute;
}

.PopUpModule__ScrollContainerOuter {
  display: table;
  width: 100%;
  height: 100%;
}

.PopUpModule__ScrollContainer {
  display: table-cell;
  vertical-align: middle;
}

.PopUpModule__Container {
  background-color: #fff;
  position: relative;
  width: 100%;
  max-width: 30em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin: auto;
  display: flex;
  flex-direction: column;
}

.PopUpModule--image-bottom {
  .PopUpModule__Image {
    order: 2;
  }
}

@include mq(large) {
  .PopUpModule--image-left {
    .PopUpModule__Container {
      flex-direction: row;
      max-width: 50em;
    }

    .PopUpModule__Content,
    .PopUpModule__Image {
      flex: 1 0 50%;
    }

    .PopUpModule__Image .FullImageContainer,
    .PopUpModule__Image .FullImage {
      height: 100%;
      object-fit: cover;
    }
  }
}

.PopUpModule__Buttons {
  margin-bottom: 1.5em;
}

.PopUpModule__Buttons--over-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: auto;
  margin: 0;
  transform: translate(-50%, 50%);
}

.PopUpModule__ScrollContainer--align-left-top {
  vertical-align: top;
}

.PopUpModule__ScrollContainer--align-left-bottom {
  vertical-align: bottom;
}

.PopUpModule__Container--align-left-top,
.PopUpModule__Container--align-left-middle,
.PopUpModule__Container--align-left-bottom {
  margin-left: 0;
}

.Module__Headings {
  padding-top: 3em;
}

.PopUpModule__Text {
  padding: 0 2em;
}

.PopUpModule__Content {
  position: relative;
}

.PopUpModule__CloseButton {
  font-size: 1.2em;
  padding: 0.35em;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  fill: #000;

  .Icon {
    top: 0;
    vertical-align: middle;
  }
}

.PopUpModule__Description:not(:empty) {
  padding-bottom: 2em;
}
