// This settings will be user defined
.Module {
  background: #fff;
  color: #5e6d82;
}

.Module__Wrapper--default-padding {
  padding-top: 2em;
  padding-bottom: 2em;
}

.Module__Wrapper--small-padding {
  padding-top: 1em;
  padding-bottom: 1em;
}

@include mq(large) {
  .Module__Wrapper--default-padding {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .Module__Wrapper--small-padding {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.Module__Wrapper {
  max-width: calc(1520px + 3%);
  margin: 0 auto;
  padding-left: 3%;
  padding-right: 3%;
}

.Module__Title {
  line-height: 1.3;
}

.Module__Title:only-child {
  margin-bottom: 0.8em;
}

.Module__Subtitle {
  margin: 0.5em 0 0.8em;
}

@include mq(large) {
  .Module__Title:only-child {
    margin-bottom: 1.5em;
  }

  .Module__Subtitle {
    margin: 0.7em 0 1.1em;
  }
}

.LayoutVariant .Module__Title {
  display: none;
}
