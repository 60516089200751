.GastroPoolInsuranceModule {
  padding: 4em 0;
}

.GastroPoolInsuranceModule__Content--empty {
  background: url("/public/erv-logo-full.svg") no-repeat center center;
  height: 8em;
}

.GastroPoolInsuranceModule__Center {
  text-align: center;
}
