// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a,
.DefaultLink {
  color: $highlighted-color;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

input,
button {
  border: none;
}

button {
  cursor: pointer;
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    padding: 0;
  }
}

input,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
}

input[disabled],
textarea[disabled],
button[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
  outline: none;
}

body {
  color: $light-text-color;
  font: 400 100% / 1.5 $text-font-stack;
  margin: 0;
}

.AspectRatioContainer {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: 50%;
  }
}

.AspectRatioContainer--0\.25::before {
  padding-top: 25%;
}

.AspectRatioContainer--0\.375::before {
  padding-top: 37.5%;
}

.AspectRatioContainer--0\.5::before {
  padding-top: 50%;
}

.AspectRatioContainer--0\.5625::before {
  padding-top: 56.25%;
}

.AspectRatioContainer--0\.6667::before {
  padding-top: 100% / 3 * 2;
}

.AspectRatioContainer--0\.75::before {
  padding-top: 75%;
}

.AspectRatioContainer--1::before {
  padding-top: 100%;
}

.AspectRatioContainer--1\.3333::before {
  padding-top: 100% * 4 / 3;
}

.AspectRatioContainer--1\.5::before {
  padding-top: 150%;
}

.AspectRatioContainer--1\.7778::before {
  padding-top: 100% * 16 / 9;
}

.AspectRatioContainer--2::before {
  padding-top: 200%;
}

.AspectRatioContainer--2\.6667::before {
  padding-top: 100% * 8 / 3;
}

// For ImageGalleryModule

.AspectRatioContainer--1\.7445::before {
  padding-top: 174.45%;
}

.AspectRatioContainer--0\.3051::before {
  padding-top: 30.51%;
}

.AspectRatioContainer--0\.6659::before {
  padding-top: 66.59%;
}

.AspectRatioContainer--0\.9716::before {
  padding-top: 97.16%;
}

.AspectRatioContainer__Content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media (max-aspect-ratio: 21 / 9) {
  .ImagesModule--full-height {
    .AspectRatioContainer::before {
      content: none;
    }

    .AspectRatioContainer__Content {
      position: static;
    }
  }
}

.FlexAlignH--left {
  justify-content: flex-start;
  text-align: left;
}

.FlexAlignH--center {
  justify-content: center;
  text-align: center;
}

.FlexAlignH--right {
  justify-content: flex-end;
  text-align: right;
}

.FlexAlignV--top {
  align-items: flex-start;
}

.FlexAlignV--center {
  align-items: center;
}

.FlexAlignV--bottom {
  align-items: flex-end;
}

.FullImage {
  width: 100%;
  height: auto;
  display: block;
}

.Site {
  line-height: 1.8;
}

.BareBtn {
  line-height: inherit;
  background-color: transparent;
  text-align: inherit;
  padding: 0;
  color: inherit;
}

// Equal height slides for react-slick,
// see https://github.com/kenwheeler/slick/issues/179#issuecomment-230038872
.slick-slider {
  .slick-track {
    display: flex;
    margin-left: 0;
    margin-right: 0;
  }

  .slick-slide {
    display: flex;
    height: auto;

    > div,
    > a {
      height: 100%;
      width: 100%;
    }
  }

  overflow: hidden;
}

.pswp__caption__center {
  text-align: center;
  font-size: 1em;
  color: #fff;
}

// This is to not let the child element
// expand to 100% width, otherwise clickling
// outside the content box wouldn’t close
// the lightbox.
.pswp__zoom-wrap {
  display: flex;
  justify-content: center;
}

.lightbox-open {
  overflow: hidden;
}

.dummy-100vh {
  height: 100vh;
  position: absolute;
  z-index: -100;
  width: 1px;
  visibility: hidden;
}

.svg-sprite-container {
  // Cannot simply be display: none; because that would hide
  // the elements in <defs>, e.g. gradients
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.scroll-padding {
  scroll-padding-top: 5em;
}
