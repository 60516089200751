.TextAlign--left {
  text-align: left;
}

.TextAlign--center {
  text-align: center;
}

.TextAlign--right {
  text-align: right;
}

.TextAlign--justify {
  text-align: justify;
  hyphens: auto;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  text-align: inherit;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 1em 0;
  padding: 0 0 0 1.4em;
}

.TextAlign--center,
.TextAlign--right {
  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    list-style-position: inside;
    padding: 0;
  }
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: "•   ";
}

.public-DraftStyleDefault-unorderedListItem > .public-DraftStyleDefault-block {
  display: inline;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 0;
}

// Overwrite pre-wrap which causes issues with Firefox
// and justified text, see https://github.com/facebook/draft-js/issues/1012
.public-DraftStyleDefault-block {
  white-space: normal;
}
