.IconsCopyright__Item {
  display: flex;
  align-items: center;
}

.IconsCopyright__ItemInner {
  display: flex;
  align-items: center;
}

.IconsCopyright__Icon {
  top: 0;
  height: auto;
  fill: currentColor;
}

.IconsCopyright__Caption {
  margin-left: 1em;
}
